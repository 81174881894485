@import 'variables';

.capitalize {
  text-transform: capitalize;
}

.paragraph {
  font-size: 20px;
  line-height: 24px;
  color: #757575;
  // color: rgba(0, 0, 0, 0.3);
}

.text-rotate {
  color: $text-rotate;
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: bold;
}

.rotate {
  position: absolute;
  top: 50%;
  text-align: center;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  left: -13px;
}
