@import 'variables';

$box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: none;
  padding: 0 15px;
  font-size: 20px;
  line-height: 24px;
  border-radius: 15px;
  height: 32px;
  min-width: 100px;

  &.btn-primary {
    background-color: $primary-color;
  }

  &.btn-gray {
    background-color: $light-grey;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}
