@import 'variables';

$tags-focus-border-color: $orange;


.tagify {
  --tags-focus-border-color : #{$tags-focus-border-color};
  border-radius: 15px;
  min-height: 52px;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  padding: 12px 8px 4px 8px;

  &[readonly]:not(.tagify--mix) {
    background-color: inherit;
  }

  &:hover {
    border-color: #FFFFFF;
  }

  .tagify__tag {
    margin: 0;
    margin-bottom: 8px;

    &:not(:last-child) {
      margin-right: 8px;
    }

    .tagify__tag-text {
      font-size: 20px;
      line-height: 24px;
      color: #333333;
    }

    .tagify__tag__box {
      border-radius: 16px;
    }

  }

  .tagify__input {
    margin: 0;
    color: #D1D1D1;
    letter-spacing: 0.25px;
    font-size: 20px;
    line-height: 24px;
    opacity: 0.6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tagify__dropdown {
  &.stakeholder-list {
    border-radius: 16px;

    .tagify__dropdown__wrapper {
      border: none;

      .tagify__dropdown__item {
        font-size: 20px;
        line-height: 24px;
        color: #333333;

        &:hover {
          background-color: $orange;
        }
      }
    }
  }
}
