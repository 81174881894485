@font-face {
  font-family: 'DB HelvethaicaMon X Reg';
  src: url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonXReg.eot?#iefix');
  src: url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonXReg.eot?#iefix') format("eot"),
  url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonXReg.woff2') format("woff2"),
  url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonXReg.woff') format("woff"),
  url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonXReg.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DB HelvethaicaMon X Reg';
  src: url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonX-75Bd.eot?#iefix');
  src: url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonX-75Bd.eot?#iefix') format("eot"),
  url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonX-75Bd.woff2') format("woff2"),
  url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonX-75Bd.woff') format("woff"),
  url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonX-75Bd.ttf') format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'DB HelvethaicaMon X Reg';
  src: url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonX-65Med.eot?#iefix');
  src: url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonX-65Med.eot?#iefix') format("eot"),
  url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonX-65Med.woff2') format("woff2"),
  url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonX-65Med.woff') format("woff"),
  url('#{$font-path}DBHelvethaicaMon/DBHelvethaicaMonX-65Med.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icon-path}#{$icomoon-font-family}.eot?m2l5e2');
  src:  url('#{$icon-path}#{$icomoon-font-family}.eot?m2l5e2#iefix') format('embedded-opentype'),
  url('#{$icon-path}#{$icomoon-font-family}.ttf?m2l5e2') format('truetype'),
  url('#{$icon-path}#{$icomoon-font-family}.woff?m2l5e2') format('woff'),
  url('#{$icon-path}#{$icomoon-font-family}.svg?m2l5e2##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


