
@import 'variables';

.link-default {
  font-size: 20px;
  line-height: 24px;
  color: $link;
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
}
