@import './variables';

.form {
  .textarea-form {
    resize: none;
    width: 100%;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 4px 15px;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    height: auto;
    min-height: 77px;
    overflow: hidden;

    &:read-only,
    &:disabled {
      border-color: #FFFFFF;
      background-color: inherit;
    }

    &::placeholder {
      color: #D1D1D1;
      line-height: 50px;
    }

    &.error {
      border-color: $danger;
    }
    &+.error {
      color: $danger;
    }
  }

  .input-form {
    background: #FFFFFF;
    border-radius: 15px;
    height: 30px;
    padding: 0 15px;
    width: 100%;
    font-size: 20px;

    &::placeholder {
      color: #D1D1D1;
      font-weight: normal;
      line-height: 50px;
    }

    &:disabled {
      opacity: 0.5;
    }

    &.error {
      border-color: $danger;
    }

    &+.error {
      color: $danger;
    }


    &:read-only,
    &:disabled {
      border-color: #FFFFFF;
      background-color: inherit;
    }

  }

  .radio-form {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      // &:checked~.checkmark {
      //   background-color: #2196F3;
      // }

      &:checked~.checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #eee;
      border-radius: 50%;
      border: 1px solid #000000;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 2px;
        left: 2px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #FBB034;
      }
    }

    &:hover input~.checkmark {
      background-color: #ccc;
    }

  }

}

.input {
  background: #FFFFFF;
  border-radius: 15px;
  line-height: 30px;
  padding: 0 15px;
  width: 100%;
  margin: 4px 0;
  color: #333333;
  font-size: 20px;
}

.postion-relative {
  position: relative;
}
