.container {
  width: 100%;
  margin: 0 auto;
}

.container-fluid {
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}

@media(min-width: 1200px) {
  .container {
    width: 1140px;
  }
}
