$icon-path: '/assets/icon/' !default;
$image-path: '/assets/images/' !default;
$font-path: '/assets/fonts/' !default;
$warning: #fd3c3c;
$danger: #e12222;
$spindle: #bfd9d2;
$primary-color: #fbb034;
$primary-color-hover: #ffd077;
$primary-color-disable: #ffe6b5;
$white: #ffffff;
$white-three: #e7e7e7;
$white-smoke: #f4f4f4;
$white-four: #f0f0f0;
$btn-new-news: #ff5a44;
$border-black: #000000;
$light-grey: #d1d1d1;
$light-grey-hover: #dfdfe0;
$mouse-hover-blue: #0b236b;
$grey: #aaaaaa;
$warm-grey: #999999;
$bright-grey: rgba(95, 96, 98, 0.2);
$border-grey: $grey;
$border-error: 1px solid #dc3545 !important;
$bg-color: #f9f9f9;
$bg-grey: #efefef;
$bg-gray-2: #8A8A8A;
$warm-grey-three: #6f6f6f;
$navbar-bg-color: $white;
$navbar-brand-text-color: $primary-color;
$footer-color: #333333;
$text-color: #000000;
$text-color-white: $white;
$radius: calc(0.875rem - 1px);
$btn-black-color: black;
$btn-reject-color: #ff6565;
$btn-reject-color-hover: #ff8b8b;
$btn-approve-color: #6cdd9a;
$btn-approve-color-hover: #9fdfb9;
$background-btn-cancel: #d7d7d7;
$banner-height: 300px;
$table-odd: #efefef;
$table-even: #e9e9e9;
$table-link: #3B67C9;
$link: #365BC8;
$soft-blue: #86A1DC;
$light-blue: #DBE2F1;
$soft-grey: #e5e5e5;
$tranquil: #dae6e4;
$carousel-dot-color: #a5a5a5;
$carousel-dot-active-color: #fabb52;
$carousel-control-width: 125px;
$carousel-icon-width: 24px;
$carousel-icon-height: 42px;
$carousel-prev-icon: '/assets/icon/left-arrow.svg';
$carousel-next-icon: '/assets/icon/right-arrow.svg';
$alert-image: '/assets/icon/app/alert.svg';
$card-header: #3B67C9;
$card-header-complete: #757575;

$toast-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
$toast-hover: 2px 2px 4px rgba(0, 0, 0, 0.5);

$btn-submit: $btn-black-color;
$btn-reset: #f7f7f7;
$btn-font-color: #666666;
$placeholder-color: #aaa;
$disabled-color: #E6E6E6;

$amaranth: #dc3545;
$btn-min-width: 6.25rem;
$text-blue: #337ab7;
$coral: #ff5a44;
$dusty-orange: #f2784b;
$maize-two: #f4d03f;
$silver: #bfcad1;
$desert: #c8d046;
$cool-grey: #95a5a6;
$tealish: #24b699;
$tealish-two: #1ba39c;
$pinkish-grey: #bfbfbf;
$dusk: #5d4e7b;
$current-activity: #FF8400;

$size16: 1rem;
$size18: 1.125rem;
$size20: 1.25rem;
$size24: 1.5rem;
$size28: 28px;
$size30: 1.875rem;

$top-z-index: 100;
$nav-top-height: 45px;


$royal-blue: #3B67C9;
$faded-blue: #5c6eba;
$light-teal: #a2eac0;
$success: #50B178;
$soft-success: #e1eee6;
$weight: (thin: 100,
  extra-light: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800,
  black: 900);

$font-helvetica-mon: 'DB HelvethaicaMon X Reg',
Helvetica,
Arial,
sans-serif;

@function map-get-strict($map, $key) {
  @if map-has-key($map, $key) {
    @return map-get($map, $key);
  } @else {
    @error "ERROR: Specified index does not exist in the mapping";
  }
}


//new color from design system
$cerulean-blue: #2C5CC8;
$orange: #fbb034;
$cornflower-blue: #6A92ED !default;
$orange: #FBB034 !default;
$dark-orange: #FF8400 !default;
$red-stop: #FF6565 !default;
$green-pass: #6CDD9A !default;
$checkbox-s-ca: #1345B6;
$gainsboro: #E0E0E0;
$text-disable: #8A8A8A;

$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;
$icon-memo: "\e901";
$icon-bookmark: "\e906";
$icon-cancel: "\e900";
$icon-close: "\e902";
$icon-delete: "\e903";
$icon-down: "\e904";
$icon-edit: "\e905";
$icon-next: "\e907";
$icon-plus: "\e908";
$icon-previous: "\e909";
$icon-tick: "\e90c";
$icon-up: "\e90d";
$matrix-orange: #FFCB9B;
$matrix-red:  #FF8A80;
$matrix-yellow: #FFEEB0;
$matrix-green: #BEFFBD;
$matrix-meaning-red: #AD0505;
$matrix-meaning-orange: #E55F14;
$matrix-meaning-yellow: #E7A11A;
$matrix-meaning-green:#3A8358;
$text-rotate:#757575;