.flex {
  display: flex;

  &.flex-direction-row {
    flex-direction: row;
  }

  &.flex-direction-column {
    flex-direction: column;
  }

  &.flex-align-item-center {
    align-items: center;
  }

  &.flex-justify-content-center {
    justify-content: center;
  }

  &.flex-align-item-flex-start {
    align-items: flex-start;
  }

  &.flex-align-item-flex-end {
    align-items: flex-end
  }


  &.flex-justify-content-space-between {
    justify-content: space-between;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &.flex-column-gap-10 {
    column-gap: 10px;
  }

  &.flex-justify-content-space-around {
    justify-content: space-around;
  }
}
