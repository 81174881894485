.modal {
  z-index: 101 !important;
  &>* {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }


  .modal-content {
    border-radius: 20px;
  }

  .modal-header {
    border: none;

    .title-header {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #FBB034;
    }
  }


  .modal-body {
    background: #EFEFEF;
    border-radius: 20px;
    margin: 0 16px;
    padding: 20px 11px;
    min-height: 400px;
  }
}

// $modal-radius: 0.9375rem;


// .modal-content {

//   border-radius: $modal-radius;
//   min-width: 417px;
//   max-width: 1000px;
//   min-height: 280px;
//   max-height: 1000px;

//   .modal-header, .modal-body {
//     border-bottom: 0;
//     padding-bottom: 0;
//   }

//   .modal-title {
//     color: $primary-color !important;
//     font-weight: normal;
//   }

//   .modal-header .close {
//     padding: 0.5rem 1rem;
//   }

//   .modal-content {
//     font-size: 1.25rem;
//     border: none;
//     font-weight: 200;
//     line-height: 1;
//     min-height: 191px;
//     display: flex;
//     justify-content: center;
//     width: 100%;

//     img {
//       width: 70px;
//       height: 70px;
//     }


//     .modal-icon {
//       margin-bottom: 0.9375rem;
//     }

//     .block-primary-text {
//       padding: 0 0.9375rem;
//       text-align: center;
//       width: 100%;
//       color: $primary-color;
//       font-size: 1.75rem;
//     }
//     .block-primary-text-left {
//       width: 100%;
//       color: $primary-color;
//       font-size: 1.75rem;
//     }

//     .content-description {
//       width: 420px;
//       margin: 0.9375rem 1.25rem;
//       font-size: 1.25rem;
//       text-align: center;
//       align-self: center;
//     }
//     .feedback-content {
//       min-width: 420px;
//       margin: 0.9375rem 1.25rem;
//       font-size: 1.25rem;
//       text-align: center;
//     }

//   }

//   .modal-footer {
//     border-top: 0;
//   }

//   .close {
//     font-size: 2rem;
//     opacity: 1 !important;
//   }

//   button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
//     cursor: pointer;
//     outline: none;
//   }
// }

// .content-detail-wrap {
//   font-size: 1.125rem;

//   .title {
//     color: $primary-color;
//   }

//   .text-date-bold {
//     font-weight: bold;
//   }

//   span {
//     a {
//       cursor: pointer;
//     }

//     i {
//       font-size: .75rem;
//       color: $light-grey;
//     }
//   }
// }

// .confirm-modal-wrap {
//   min-width: 415px;
//   min-height: 280px;
//   max-width: 700px;
// }

// #popup-warning-modal-wrap {
//   .modal-content {

//     img {
//       width: unset;
//       min-width: 420px;
//       max-width: 970px;
//       max-height: 500px;
//       min-height: 210px;
//       height: unset;
//     }
//   }
// }

// #announcement-modal, #activity-modal {
//   width: 800px;
// }
