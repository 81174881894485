/* You can add global styles to this file, and also import other style files */

@import 'assets/sass/variables';
@import 'assets/sass/containers';
@import 'assets/sass/fonts';
@import 'assets/sass/text';
@import 'assets/sass/flex';
@import 'assets/sass/button';
@import '~@yaireo/tagify/src/tagify';
@import 'assets/sass/tagify';
@import 'assets/sass/override';
@import 'assets/sass/link';
@import 'assets/sass/modal';
@import 'assets/sass/image';
@import 'assets/sass/form';
@import 'assets/sass/tooltip';
@import 'assets/sass/swal';

// sweet alert
@import '~sweetalert2/src/sweetalert2.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: DB HelvethaicaMon X Reg, Helvetica, Arial, sans-serif;
}

html,
body {
  font-family: DB HelvethaicaMon X Reg, Helvetica, Arial, sans-serif;
  background-color: $bg-color;
  min-height: 100%;
  font-size: 16px;
  color: #000;
  scroll-behavior: smooth;
}

input {
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.25px;
}
