@import 'variables';
input, textarea {
  outline: none;
  border: 1px solid #FFFFFF;
  &:focus {
    border: 1px solid $orange;
  }
}
ul {
  list-style-position: inside;
}