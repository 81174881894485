.tooltip {
  font-size: 16px;
  line-height: 19px;
  color: #231F20;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 5px;
}
body .tippy-box {
  border-radius: 10px;
}
